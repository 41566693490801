<template>
     <div class="overlay-panel flex-container flex-col">
        <div class="panel-header p24 pb0">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">Benvenuto</h2>
        </div>
        <form class="login flex-container flex-col" @submit.prevent="login"> 
            <div class="pl24 pr24 pb0">
              <p>Inserisci le tue credenziali</p>
              <div class="flex-container mb24">
                  <label class="flex-container flex-col">
                      <div class="flex-container heading-and-error">
                        <h3 class="bebas nomargin">Email</h3>
                        <ErrorMessage :validationStatus="vuel.uemail" />
                      </div>
                      
                      <input 
                          required 
                          v-model="uemail" 
                          type="email" 
                          placeholder="Email"
                          @blur="handleBlur('uemail')"
                          class="noborder nopadding pl8"
                      />
                    
                  </label>
              </div>
              <div class="flex-container">
                  <label class="flex-container flex-col">
                    <div class="flex-container heading-and-error">
                      <h3 class="bebas nomargin">password</h3>
                      <ErrorMessage :validationStatus="vuel.password" />
                    </div>
                      
                      <input 
                          required 
                          v-model="password" 
                          type="password" 
                          placeholder="Password"
                          @blur="handleBlur('password')"
                          class="noborder nopadding pl8"
                      />
                      
                  </label>
              </div>
            </div>
             <p class="pl24 pr24">Hai dimenticato la password ?<router-link to="/forgot">RESET</router-link></p>
            <div class="flex-container flex-end-align">
                <input :disabled="vuel.$invalid" type="submit" value="Login" class="w100 noborder nopadding bebas">
            </div>
        </form>
       
    </div> 

</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { DO_LOGIN, ME, MESSAGE/* , FORGOT_PASSWORD */ } from '@/store/VuexPaths'
import ErrorMessage from "@/components/formelements/ErrorMessage";
import {useCapabilities} from '@/utils/Capabilities'

export default {
  components:{
    ErrorMessage
  },
  setup (){
    const store = useStore()
    const router = useRouter()

    const password = ref(null);
    const uemail = ref(null);

    const rules = {
      uemail: { required, email },
      password: { required, minLength: minLength(8) }
    }
    const vuel = useVuelidate(
      rules, 
      { 
        uemail, 
        password 
      }
    )

    const handleBlur = (key) =>{
      vuel.value[key].$dirty = true;
    }


    const {isAdmin, isAdvertiser} = useCapabilities()

    const login = async () => {
      vuel.$dirty = true;
      if (!vuel.$error) {
        try {
          await store.dispatch(DO_LOGIN, { email:uemail.value, password:password.value })
          await store.dispatch(ME)
          if(isAdmin()){
            router.push('/')
          } else if (isAdvertiser()){
            router.push('/campaigns')
          }
          
        } catch(err) {
          await store.dispatch(MESSAGE, { title:'error login', message:err.message })
          console.log(err.message)
        }
      }
    }
    return{
      password,
      uemail,
      vuel,
      handleBlur,
      login,
    }
  }
}
</script>
